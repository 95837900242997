.imagen_banner{
    top:-100px;
}

.imagen_superfamilia{
    max-width:120px;
}

.titulo_superfamilia{
    font-size: 34px!important;
}

.cont_general_titulo{
    top:-120px;
}
.contenedor_imagen_familia{
    height:250px;
}


@media (max-width:480px){
    .contenedor_imagen_familia{
        height:180px;
    }
   
    
}
@media (max-width:380px){
    .titulo_superfamilia{
        font-size: 24px!important;
    }
    .nombre_familia_img{
        font-size:14px!important;
    }
    .contenedor_imagen_familia{
        height:150px;
    }
}

@media (max-width:768px){
    .imagen_superfamilia{
        max-width:100px;
    }
    .cont_general_titulo{
        top:-110px;
    }
    .imagen_banner{
        top:0px;
    }
}
@media (max-width:576px){
    .imagen_superfamilia{
        max-width:80px;
    }
    .cont_general_titulo{
        top:-100px;
    }
    
}

