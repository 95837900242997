.bg-primario{
  background-color:"#ff6400"!important;
}
.text-primario{
  color:"#ff6400"!important;
}
.bg-secundario{
  background-color:"#1a1919"!important;
}
.text-secundario{
  color:"#1a1919"!important;
}